








































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class Guest extends Vue {
    guests = {
        zh: [
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/06.Martin%20Edward%20Hellman.png",
                name: "马丁·爱德华·赫尔曼",
                title: "2015年图灵奖共同得主",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/07.Whitfield%20%E2%80%98Whit%E2%80%99%20Diffie.png",
                name: "惠特菲尔德·迪菲",
                title: "2015年图灵奖共同得主",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/08.Werner%20Baumann.png",
                name: "沃纳·保曼",
                title: "德国拜耳集团CEO",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/09.Roger%20David%20Kornberg%20Laureate.png",
                name: "罗杰·大卫·科恩伯格",
                title: "2006年诺贝尔化学奖得主",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/10.Stephen%20Perry.png",
                name: "斯蒂芬·佩里",
                title: "英国四十八家集团俱乐部主席，伦敦出口有限公司常务董事",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/01.leijun.png",
                name: "雷军",
                title: "小米集团创始人、董事长兼首席执行官",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/02.huhoukun.png",
                name: "胡厚崑",
                title: "华为技术有限公司副董事长，轮值董事长",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/03.dongjin.png",
                name: "董进",
                title: "北京微芯区块链与边缘计算研究院院长",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/04.tianguoli.png",
                name: "田国立",
                title: "中国建设银行董事长",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/05.zhangboli.png",
                name: "张伯礼",
                title: "中国工程院院士，医药卫生学部主任",
            },
        ],
        en: [
            
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/06.Martin%20Edward%20Hellman.png",
                name: "Martin Edward Hellman",
                title: "2015 A.M. Turing Award Laureates",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/07.Whitfield%20%E2%80%98Whit%E2%80%99%20Diffie.png",
                name: "Whitfield ‘Whit’ Diffie",
                title: "2015 A.M. Turing Award Laureates",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/08.Werner%20Baumann.png",
                name: "Werner Baumann",
                title: "CEO of Bayer AG",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/09.Roger%20David%20Kornberg%20Laureate.png",
                name: "Roger David Kornberg Laureate",
                title: "Nobel Prize 2006",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/10.Stephen%20Perry.png",
                name: "Stephen Perry",
                title: "the Managing Director of London Export Corporation and the Chairman of the 48 Group Club",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/01.leijun.png",
                name: "Lei Jun",
                title: "Chairman of Xiaomi Technology",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/02.huhoukun.png",
                name: "Hu Houkun",
                title: "Deputy Chairman, Rotating Chairman, Huawei Technologies Co., Ltd.",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/03.dongjin.png",
                name: "Dong Jin",
                title: "General Director of Beijing Academy of Blockchain and Edge Computing",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/04.tianguoli.png",
                name: "Tian Guoli",
                title: "Chairman of China Construction Bank",
            },
            {
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/%E5%98%89%E5%AE%BE%E5%A4%B4%E5%83%8F/05.zhangboli.png",
                name: "Zhang Boli",
                title: "Academician of Chinese Academy of Engineering and Director of Department of Medicine and Health",
            },
        ],
    };

    get img_src() {
        const base_src =
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/";
        return this.$i18n.locale == "en"
            ? `${base_src}guest_en.png`
            : `${base_src}guest_zh.png`;
    }
}
