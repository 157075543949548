














import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class Partner extends Vue {
    get img_src(){
        const base_src="https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/zgc_2022/"
        return this.$i18n.locale=="en"?`${base_src}partner_zh.png`:`${base_src}partner_zh.png`
    }
}
