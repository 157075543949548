



























import { Vue, Component } from "vue-property-decorator";
import {
    PageTitle,
    Guest,
    Partner,
    TradeActivity,
} from "@/components/home/zgc";
@Component({
    components: {
        "page-title": PageTitle,
        guest: Guest,
        partner: Partner,
        "activity-trade": TradeActivity,
    },
})
export default class ZGCView extends Vue {}
